import Houdini from 'houdinijs/dist/js/houdini.polyfills';
import { getParents } from './utility';

//
// NAVIGATION MENU
//
// toggle menu with menu-icon
document.querySelector('.navbar-burger').addEventListener('click', (e) => {
  e.preventDefault();
  // Toggle the 'is-active' class on both the 'navbar-burger' and the 'navbar-menu'
  if (document.querySelector('.navbar').classList.contains('is-active')) {
    document.querySelector('.navbar').classList.remove('is-active');
    document.querySelector('.navbar-burger').classList.remove('is-active');
    document.querySelector('#navbarMain').classList.remove('is-active');
  } else {
    document.querySelector('.navbar').classList.add('is-active');
    document.querySelector('.navbar-burger').classList.add('is-active');
    document.querySelector('#navbarMain').classList.add('is-active');
  }
});
//
// shrink navbar on scrolling
const navbar = document.querySelector('.navbar');
window.onscroll = () => {
  if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
    navbar.classList.add('navbar__shrink');
  } else {
    navbar.classList.remove('navbar__shrink');
  }
};

//
// LANG SWITCHER
//
document.querySelector('.lang-dropdown .dropdown-trigger').addEventListener('click', () => {
  if (document.querySelector('.lang-dropdown').classList.contains('is-active')) {
    document.querySelector('.lang-dropdown').classList.remove('is-active');
  } else {
    document.querySelector('.lang-dropdown').classList.add('is-active');
  }
});

//
// Add query params to language switcher
// prevents checkout from loosing products, coupons and other stuff
//

const elements = document.querySelectorAll('.lang > a');
Array.prototype.forEach.call(elements, (el) => {
  // eslint-disable-next-line no-param-reassign
  el.href += window.location.search;
});

//
// Initialize accordions
//
const accordion = new Houdini('[data-houdini-group]', {
  isAccordion: true,
});

// Open accordion from URL hash
if (window.location.hash) {
  const hash = window.location.hash.substring(1);
  const hashEl = document.getElementById(hash);

  if (hashEl) {
    // Expand target element
    accordion.expand(`#${hash}`);

    // Expand parents
    const parents = getParents(hashEl, '[data-houdini-group]');
    Array.prototype.forEach.call(parents, (el) => {
      accordion.expand(el);
    });

    // Scroll to element
    document.getElementById(hash).scrollIntoView();
  }
}
