// CSS and SASS files
import 'lazysizes';
import 'cookieconsent';
import './index.scss';
import bulmaCarousel from 'bulma-carousel';
// import ModalVideo from 'modal-video';
import './components/navigation';

//
// SteadyTemp Video
//

/* eslint-disable-next-line no-new */
/*
new ModalVideo('.js-modal-btn', {
  youtube: {
    rel: 0,
    showinfo: 0,
    modestbranding: 1,
    iv_load_policy: 3,
  },
});
 */

bulmaCarousel.attach('#carousel-demo', {

  navigationSwipe: true,
  loop: true,
  autoplay: true,
  breakpoints: [{ changePoint: 750, slidesToShow: 1, slidesToScroll: 1 },{ changePoint: 1200, slidesToShow: 2, slidesToScroll: 1 }],
});

// Init Modal view for Success Stories
function openModal(target) {
  const $target = document.getElementById(target);
  document.documentElement.classList.add('is-clipped');
  $target.classList.add('is-active');
  // initalize Press carousell after modal was displayed.
  // If we init on page load, carousel gets a width of 0 because modal is not shown yet.
  bulmaCarousel.attach(`#press-${$target.id}`, {
    slidesToScroll: 1,
    slidesToShow: 1,
    navigationSwipe: true,
    navigation: true,
    pagination: true,
  });
}

function closeModals() {
  document.documentElement.classList.remove('is-clipped');
  document.querySelectorAll('.modal').forEach((n) => {
    n.classList.remove('is-active');
  });
}

if (document.querySelector('.stories-modal')) {
  console.log('Stories Initialized...');
  document.querySelectorAll('.stories-modal').forEach(n => n.addEventListener('click', () => {
    openModal(n.dataset.modal);
  }));
}

if (document.querySelector('.modal-close')) {
  console.log('Modal Stories closed...');
  document.querySelectorAll('.modal-close').forEach(n => n.addEventListener('click', () => {
    closeModals();
  }));
}
document.addEventListener(
  'DOMContentLoaded', () => {
    const e = document.getElementById('subscribeNewsletter');
    if (e) {
      e.onclick = () => {
        console.log('Creating Mailchimp Overlay');
        const m = document.getElementById('mailchimp-modal');
        if (m) {
          m.classList.add('is-active');
        }
      };
    }
  },
);

const ddate = new Date();
let crstr = '© ';
crstr += ddate.getFullYear().toString();
console.info('Date: ' + ddate.getFullYear());
document.getElementById('copyright').innerHTML = crstr;
