export function getQueryString(name) {
  const urlParams = new URLSearchParams(window.location.search);
  const value = urlParams.get(name);

  if (value) {
    return value;
  }
  throw new Error(`QueryString for ${name} not found`);
}

export function getParents(elem, selector) {
  // Element.matches() polyfill
  if (!Element.prototype.matches) {
    Element.prototype.matches = Element.prototype.matchesSelector
    || Element.prototype.mozMatchesSelector
    || Element.prototype.msMatchesSelector
    || Element.prototype.oMatchesSelector
    || Element.prototype.webkitMatchesSelector
    /* eslint-disable-next-line func-names */
    || function (s) {
      const matches = (this.document || this.ownerDocument).querySelectorAll(s);
      let i = matches.length;
      /* eslint-disable-next-line no-plusplus, no-empty */
      while (--i >= 0 && matches.item(i) !== this) {}
      return i > -1;
    };
  }

  // Set up a parent array
  const parents = [];

  // Push each parent element to the array
  /* eslint-disable-next-line no-param-reassign */
  for (; elem && elem !== document; elem = elem.parentNode) {
    if (selector) {
      if (elem.matches(selector)) {
        parents.push(elem);
      }
      /* eslint-disable-next-line no-continue */
      continue;
    }
    parents.push(elem);
  }

  // Return our parent array
  return parents;
}
